import axios from 'axios'
import { Commit, Dispatch } from 'vuex'
import router from '@/router'

const baseURL = process.env.VUE_APP_AUTH_SVC_URL + 'clients'

export default ({
  namespaced: true,
  state: {
    auth_clients: [],
    auth_clients_archived: [],
    item: {},
    status: 'loading'
  },
  actions: {
    async index({ commit }: { commit: Commit }, payload = {}) {
      commit('SET_STATUS', 'loading')
      const { data } = await axios.get(`${baseURL}`, { params: { ...payload } })
      commit('SET_ITEMS', data)
      commit('SET_STATUS', 'completed')
      return data
    },

    async indexArchive({ commit }: { commit: Commit }, payload = {}) {
      commit('SET_STATUS', 'loading')
      const { data } = await axios.get(`${baseURL}?is_archived=true`, { params: { ...payload } })
      commit('SET_ITEMS_ARCHIVED', data)
      commit('SET_STATUS', 'completed')
      return data
    },

    async store({ dispatch }: { dispatch: Dispatch }, payload = {}) {
      const { data } = await axios.post(`${baseURL}`, payload)
      router.push('/auth-clients')
      return data
    },

    async update({ dispatch }: { dispatch: Dispatch }, payload = { id: '' }) {
      const { data } = await axios.put(`${baseURL}/${payload.id}`, payload)
      return data
    },

    async show({ commit }: { commit: Commit }, id = '') {
      const { data } = await axios.get(`${baseURL}/${id}`)
      return data
    },

    async destroy({ dispatch }: { dispatch: Dispatch }, id = '') {
      const { data } = await axios.delete(`${baseURL}/${id}`)
      dispatch('index')
      return data
    },

    async archive({ dispatch }: { dispatch: Dispatch }, id = '') {
      const { data } = await axios.delete(`${baseURL}/${id}`)
      router.push('/auth-clients')
      return data
    },

    async restore({ dispatch }: { dispatch: Dispatch }, id = '') {
      const { data } = await axios.post(`${baseURL}/${id}/restore`)
      router.push('/auth-clients')
      return data
    }

  },
  mutations: {
    SET_ITEMS(state: any, data: any) {
      state.auth_clients = data.data
    },
    SET_ITEMS_ARCHIVED(state: any, data: any) {
      state.auth_clients_archived = data.data
    },
    SET_STATUS(state: any, payload: string) {
      state.status = payload
    },
  },
  getters: {
    items(state: any) {
      return state.auth_clients
    },
    items_archived(state: any) {
      return state.auth_clients_archived
    },
    status(state: any) {
      return state.status
    }
  }
})