<template>
  <a-row
    style="margin-bottom: 15px;"
    v-if="showSearch"
  >
    <a-col class="search">
      <a-input-search
        placeholder="search"
        style="width: 300px"
        v-model:value="form.search"
        @input="
          debounce(() => {
            form.search = $event.target.value;
          })
        "
      />
      <!-- <a-button type="primary" @click="$emit('create')" v-if="showCreateButton">
        <template #icon><PlusOutlined /></template> {{ addText }}
      </a-button> -->

    </a-col>
  </a-row>

  <a-table
    bordered
    size="small"
    row-key="id"
    :pagination="false"
    :loading="loading"
    :columns="columns"
    :data-source="data"
    style="overflow-x: auto;"
  >

    <template v-slot:action="{ record }">
      <a-space>
        <a-button
          class="button-edit"
          @click="$emit('edit', record)"
          size="small"
        >
          <EyeFilled />
        </a-button>
        <!-- <a-popconfirm
          title="Sure to delete?"
          @confirm="onDestroy(record)"
        >
          <a-button type="danger" size="small" v-if="showDeleteButton">
            Delete</a-button
          >
        </a-popconfirm> -->
      </a-space>
    </template>
    <template v-slot:last_updated="{ record }">
      {{ dateTime(record.updated_at) }}<br />
      <span><i>{{record.updater.first_name}} {{record.updater.last_name}}</i></span>
    </template>
    <template v-slot:wesm_resource="{ record }">
        <span v-if="record.wesm_resource !== '0' ">{{record.wesm_resource}} - {{record.wesm_capacity}}MW</span>
        <span v-else>{{record.wesm_resource}}</span>
    </template>
    <template v-slot:no_of_plants="{ record }">
      {{ record.extra.plants }}
    </template>
    <template v-slot:delegation_name="{ record }">
      {{ record.recipientuser.first_name + " " + record.recipientuser.last_name }}
    </template>
    <template v-slot:username="{ text }">
      {{ text.first_name + " " + text.last_name }}
    </template>
    <template v-slot:boolean="{ text }">
      {{ text ? 'Yes' : 'No'  }}
    </template>

    <template v-slot:report_type="{ record }">
      <template v-if="record.group_for == 'email'">
        {{ record.type }}
      </template>
      <template v-else>
        <span>SMS Report</span>
      </template>

    </template>

    <!-- outage -->
    <template v-slot:plant_unit_code="{ record }">
     
      
    <div v-if="(record.outage_engine == null)"> 
      <a-tag
       
        color="#00aeef"
        v-for="item in record.outage_log_unit"
        :key="item.id"
        class="units"
        
      >
        {{( item.engines != null ? item.engines.name:"No Engines")}}
      </a-tag>
    </div>
    
    <div v-if="(record.outage_engine != null)">
      <a-tag
        color="#00aeef"
        class="units"

      >
        {{ record.outage_engine != null?
record.outage_engine.engine.name:"No Engines"}}
      </a-tag>
    </div>
    
    </template>
    <!-- event summary -->
    <template v-slot:reason="{ record }">
      <div v-if="record.outage_log_unit['0']"> 
      <div
        v-for="item in record.outage_log_unit"
        :key="item.id"
      >
        {{ item.reason}}
      </div>
      </div>

      <div v-if="!record.outage_log_unit['0']"> 
        {{ record.reason}}
      </div>

    </template>
    <template v-slot:outage_log_unit="{ record }">
      <a-tag
        color="#00aeef"
        v-for="(item, index) in record.outage_log_unit"
        :key="index"
        class="units"
      >
        {{ item.engines.name}}
      </a-tag>

    </template>
    <template v-slot:outage_status="{ record }">
      <div v-if="record.outage_log_unit['0']"> 
      <div
        v-for="item in record.outage_log_unit"
        :key="item.id"
        class="units"
      >
      <a-tag :color="item.isOver === 1 ? '#0073b7' : '#dd4b39'">{{ item.isOver === 1 ? 'Closed' : ' Open ' }}</a-tag>
      </div>
      </div>
      <div v-if="!record.outage_log_unit['0']"> 
      <a-tag :color="record.isOver === 1 ? '#0073b7' : '#dd4b39'">{{ record.isOver === 1 ? 'Closed' : ' Open ' }}</a-tag>
      </div>
    </template>
    <template v-slot:outage_created="{ record }">
      {{ dateAndTime(record.created_at) }}<br />
    </template>
    <template v-slot:outage_date="{ record }">
      <div v-if="record.outage_log_unit['0']"> 
      <div
        v-for="item in record.outage_log_unit"
        :key="item.id"
      >
        {{ dateAndTime(item.start_date) }}
      </div>
      </div>
      <div v-if="!record.outage_log_unit['0']"> 
        {{ dateAndTime(record.start_date) }}
      </div>
    </template>
    <template v-slot:template_plant="{ record }">
      <div
        v-for="item in record.extra.name"
        :key="item.id"
      >
        {{ item}}
      </div>
    </template>
    <template v-slot:outage_end_date="{ record }">
      <div v-if="record.outage_log_unit['0']"> 
      <div
        v-for="item in record.outage_log_unit"
        :key="item.id"
      >
        {{ dateAndTime(item.end_date) }}
      </div>
      </div>
      <div v-if="!record.outage_log_unit['0']"> 
        {{ dateAndTime(record.end_date) }}
      </div>
    </template>
    <template v-slot:cause_code="{ record }">
      <div v-if="(!record.cause_codes[0])"> 
      <div
        v-for="(item, index) in record.outage_log_unit"
        :key="index"
      >
        <span v-if="item.cause_codes.length > 0">
          {{item.cause_codes[0].code}}
        </span>
        <span style="color:red" v-else>
       
         No Cause Code

        </span>

      </div>
      </div>
      <div v-if="(record.cause_codes.length != 0)"> 
     
        <span v-if="record.cause_codes.length > 0">
          {{record.cause_codes[0].code}}
        </span>

      </div>
      <!-- {{ record.cause_codes['0'] ? record.cause_codes['0'].code + " - " + record.cause_codes['0'].description.name  :''}}<br /> -->
    </template>
    <template v-slot:occurence_capacity="{ record }">
      <div v-if="record.outage_log_unit['0']"> 
      <div
        v-for="item in record.outage_log_unit"
        :key="item.id"
      >
        {{ item.occurence_capacity}}
      </div>
      </div>
      <div v-if="!record.outage_log_unit['0']"> 
     
        {{ record.occurence_capacity}}
      </div>

      <!-- {{record.outage_log_unit[0].occurence_capacity}} -->
      <!-- {{ record.cause_codes['0'] ? record.cause_codes['0'].code + " - " + record.cause_codes['0'].description.name  :''}}<br /> -->
    </template>
    <template v-slot:work_flow_status="{ record }">
      <template v-if="record.work_flow_status == 'VP_APPROVED'">
        OPS MANAGER APPROVED
      </template>
      <template v-else>
        {{record.work_flow_status}}
      </template>
    </template>
    <!-- <template v-slot:remaining_time>
      {{ timeValue }}<br />
    </template> -->
    <template v-slot:remaining_time="{ record }">
      {{ timeValueCompute(record.start_date) }}<br />
    </template>
    <!-- outage -->

    <!-- sms reports -->
    <template v-slot:sms_recipients="{ record }">
      <template v-if="record.recipients && record.recipients.length > 3">
        <a class="cursor">

          <template v-for="(recipient, index) in record.recipients">

            <template v-if="index < 2">
              {{ recipient.recipient }},
            </template>
            <template v-else-if="index == 3">
              and {{ (recipient.recipient.length - 2) }} more...
            </template>

          </template>

        </a>
      </template>
      <template v-else>

        <template v-for="(recipient, index) in record.recipients">

          {{ recipient.recipient }}<template v-if="index != (record.recipients.length - 1)">,</template>

        </template>

      </template>
    </template>
    <template v-slot:sms_recipients_submitted_by="{ record }">
      <template v-if="record.report.sender">
        {{ record.report.sender.extra.fullname }}<br />
      </template>
    </template>

    <template v-slot:auth_client_status="{ record }">
      <a-tag :color="record.is_active ? '#0073b7' : '#dd4b39'">{{ record.is_active ? 'Active' : 'Inactive' }}</a-tag>
    </template>
    <template v-slot:submitted_on="{ record }">
      {{ smsSubmittedOn(record.created_at) }}<br />
    </template>
    <template v-slot:submitted_by="{ record }">
      <template v-if="record.sender">
        {{ record.sender.extra.fullname }}<br />
      </template>
    </template>

    <template v-slot:recipient_status="{ record }">
      <a-tag :color="record.isSent  ? '#00a65a' : 'yellow'">{{ record.isSent ? record.extra.status: record.extra.status }}</a-tag>
    </template>
    <template v-slot:recipient_code>
     <span>{{outage_code}}</span>
    </template>
    <template v-slot:sent_on="{ record }">
      {{ smsSubmittedOn(record.sent_at) }}<br />
    </template>
    <!-- sms reports -->

    <!-- email reports -->
    <template v-slot:email_report_type="{ record }">
      <a-tag :color="record.type === 0 ? '#00aeef' : '#0076a3'">{{ record.type == 0 ? 'ERC Outage Report' : 'Significant Outage Report' }}</a-tag>
    </template>
    <template v-slot:email_recipients="{ record }">
      <!-- {{record.report.to_recipients}} -->
      <template v-if="record.to_recipients && record.to_recipients.length > 3">
        <a class="cursor">

          <template v-for="(recipient, index) in record.to_recipients">
            <template v-if="index < 2">
              To: {{ recipient.recipient }},
            </template>
            <template v-else-if="index == 3">
              and {{ (record.to_recipients.length - 2) }} more...
            </template>

          </template>

        </a>

      </template>
      <template v-else>

        <template v-for="(recipient, index) in record.to_recipients">

          To: {{ recipient.recipient }}<template v-if="index != (record.to_recipients.length - 1)">,</template>

        </template>

      </template>
    </template>
    <template v-slot:email_recipients_submitted_by="{ record }">
      <template v-if="record.report.sender">
        {{ record.report.sender.extra.fullname }}<br />
      </template>
    </template>
    <!-- email reports -->

    <!-- sms recipient -->
    <template v-slot:sms_group_recipients="{ record }">
      <template
        v-for="item in record.groups"
        :key="item.id"
      >
        <a-tag v-if="item.group_for == 'sms'">
          {{ item.name }}
        </a-tag>
      </template>
    </template>
    <!-- sms recipient -->

    <!-- email recipient -->
    <template v-slot:email_group_recipients="{ record }">
      <template
        v-for="item in record.groups"
        :key="item.id"
      >
        <a-tag v-if="item.group_for == 'email'">
          {{ item.name }}
        </a-tag>
      </template>
    </template>
    <!-- email recipient -->

  </a-table>

  <a-row
    style="margin: 15px 15px;"
    v-if="data.length && showPagination"
  >
    <a-col
      class="search"
      span="24"
    >
      <div>
        <span>{{ pagination.from }} - {{ pagination.to }} of
          {{ pagination.total }}</span>
      </div>
      <div>
        <a-pagination
          size="small"
          :total="pagination.total"
          show-size-changer
          @change="onChange"
          @showSizeChange="onShowLimit"
          :current="form.page"
          :default-page-size="15"
          :page-size-options="['15', '30', '45', '60']"
        />
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import moment from 'moment';
import { useRoute } from "vue-router";

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    addText: {
      type: String,
      default: "Create",
    },
    showCreateButton: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Object,
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    showPagination: {
      type: Boolean,
      default: true
    }
  },
  emits: ["edit", "destroy", "create", "filter"],
  setup(props, { emit }) {
    const route = useRoute();
    const form = reactive({
      page: 1,
      limit: 15,
      search: "",
    });

    const timeValue = ref()
    const outage_code = ref()
    const stop = ref()
    const timer = ref()

    const filter = () => {
      emit("filter", form);
    };

    outage_code.value = route.query.outage_code

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          const { page } = toRefs(form);
          page.value = 1;
          emit("filter", form);
        }, delayMs || 500);
      };
    }

    const onChange = (payload) => {
      const { page } = toRefs(form);
      page.value = payload;
      filter();
    };

    const onDestroy = (payload) => {
      emit("destroy", payload);
      const { page } = toRefs(form);
      page.value = 1;
      filter();
    }

    const onShowLimit = (current, pageSize) => {
      const { limit } = toRefs(form);
      const { page } = toRefs(form);
      page.value = 1;
      limit.value = pageSize;
      filter();
    };

    const dateTime = (value) => {
      return moment(value).format('MMMM D YYYY');
    }

    const dateAndTime = (value) => {

      if(moment(value).format('MMM. Do YYYY HH:mm') === 'Invalid date'){
        return "N/A";
      }
     
      return moment(value).format('MMM. Do YYYY HH:mm');
    }

    const smsSubmittedOn = (value) => {
      return moment(value).format('MMM. DD, YYYY HH:mm');
    }

    const timeValueCompute = (value) => {
      // let created = moment(value);
      // let enabledTime = created.add(3, 'hours');
      // let now = new moment();

      // var ms = moment(enabledTime, "DD/MM/YYYY HH:mm:ss").diff(moment(now, "DD/MM/YYYY HH:mm:ss"));
      // var d = moment.duration(ms);
      // // console.log(d._data)
      // if (d._data.hours != 0) {
      //   timeValue.value = d._data.hours + ":" + d._data.minutes + ":" + d._data.seconds;
      // } else if (this._data.minutes != 0) {
      //   timeValue.value = d._data.minutes + ":" + d._data.seconds;
      // } else {
      //   timeValue.value = d._data.seconds;
      // }

      // if (d._data.hours <= 0 && d._data.minutes <= 0 && d._data.seconds <= 0) {
      //   // console.log(d._data.seconds)
      //   stop.value = true;
      //   timeValue.value = "00:00:00";
      //   clearInterval(timer.value);

      // }

      // return timeValue.value = "00:00:00";

      var startTime = moment(new Date(), 'YYYY-MM-DD hh:mm:ss');
      var endTime = moment(value, 'YYYY-MM-DD hh:mm:ss');
      var duration = moment.duration(endTime.diff(startTime));

      //Get Days and subtract from duration
      var days = Math.floor(duration.asDays());
      duration.subtract(moment.duration(days, 'days'));

      //Get Hours and subtract from duration
      var hours = duration.hours();
      duration.subtract(moment.duration(hours, 'hours'));

      //Get Minutes and subtract from duration
      var minutes = duration.minutes();
      duration.subtract(moment.duration(minutes, 'minutes'));

      //Get Minutes and subtract from duration
      var seconds = duration.seconds();
      duration.subtract(moment.duration(seconds, 'seconds'));

      // var hours = parseInt(duration.asHours());
      // var days = parseInt(duration.asDays());
      // var minutes = parseInt(duration.asMinutes()) % 60;
      // var date = hours + ':' + minutes
      if (minutes === 0) {
        return seconds + ' seconds left';
      }
      if (minutes && days > -1) {

        if (days >= 1) {
          return days + ' Day(s)' + ' and ' + hours + ' hours left';
        } else {
          return hours + ' Hours and ' + minutes + ' minute(s) left';
        }


      } else {
        return '00:00';

      }
      // if (endTime > startTime) {
      //   // if (hours >= 24) {
      //   //   return days + ' Day(s) ' + ''
      //   // }
      //   return date
      // } else {
      //   return date = '00:00'
      // }
    }

    onMounted(() => {
      timer.value = setInterval(function () {
        timeValueCompute();
      }, 1000);
    })

    return {
      form,
      stop,
      timeValue,
      outage_code,
      filter,
      debounce: createDebounce(),
      onChange,
      onShowLimit,
      onDestroy,
      dateTime,
      dateAndTime,
      smsSubmittedOn,
      timeValueCompute
    };
  },
});
</script>

<style lang="scss">
.search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}
.button-edit {
  background-color: #3c8dbc;
  border-color: #367fa9;
  color: white;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  border-top-color: #3c8dbc;
  border-top: 3px solid #3c8dbc;
  height: 40px;
  color: black;
  background: #fff;
  border-bottom: 1px solid #fff;
}
.units {
  display: block;
  margin-top: 2px;
  text-align: center;
}
.status {
  display: block;
  margin-top: 2px;
}
</style>
