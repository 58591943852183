import { Entity } from "@/interfaces/entity";
import { Permission } from "./permission";

export class Role implements Entity{
    id: string;
    name: string;
    permissions: Permission[];

    constructor(id = '', name = '', permissions = []){
        this.id = id;
        this.name = name;
        this.permissions = permissions;
    }
}