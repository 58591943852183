import { createStore } from 'vuex'

import AuthUser from './auth/auth'

import CauseCodeType from './cause_code/type'
import CauseCodeCategory from './cause_code/category'
import CauseCodeSubCategory from './cause_code/sub_category'
import CauseCodeDescription from './cause_code/description'
import CauseCode from './cause_code/code'

import Location from './location/location'

import Group from './recipient_management/group'
import SmsRecipient from './recipient_management/sms_recipient'
import EmailRecipient from './recipient_management/email_recipient'
import GroupMember from './recipient_management/group_member'

import BusinessUnit from './plant/business_unit'
import PlantTechnology from './plant/plant_technology'
import Plant from './plant/plant'
import Unit from './plant/unit'
import Engine from './plant/engine'
import Region from './plant/region'

import User from './user/user'
import Delegation from './user/delegation'
import Role from './user/role'
import PermissionCategory from './user/permission_category'

import ReportType from './outage/report_type'
import Classification from './outage/classification'
import OutageTemplate from './outage/outage_templates'

import Outage from './report_log/outage'
import SmsReport from './report_log/sms_report'
import EmailReport from './report_log/email_report'

import AuditLog from './audit_log/audit_log'
import AuthClient from './auth_client/auth_client'

import Wer from './wer/wer_report'
import Alarm from './alarm/alarm'

export default createStore({
  modules: {
    AuthUser,

    CauseCodeType,
    CauseCodeCategory,
    CauseCodeSubCategory,
    CauseCodeDescription,
    CauseCode,

    Location,

    Group,
    SmsRecipient,
    EmailRecipient,
    GroupMember,

    BusinessUnit,
    PlantTechnology,
    Plant,
    Unit,
    Engine,
    Region,

    Delegation,
    User,
    Role,
    PermissionCategory,

    ReportType,
    Classification,
    OutageTemplate,

    Outage,
    SmsReport,
    EmailReport,

    AuditLog,
    AuthClient,

    Wer,
    Alarm
  }
})
