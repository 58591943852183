
import { defineComponent, PropType, ref } from "vue";
import { message } from "ant-design-vue";
import { UploadOutlined } from "@ant-design/icons-vue";

interface Route {
  path: string;
  breadcrumbName: string;
  children?: Array<{
    path: string;
    breadcrumbName: string;
  }>;
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

export default defineComponent({
  props: {
    routes: {
      type: Object,
    },
    title: {
      type: String as PropType<string>,
      required: false,
    },
    subTitle: {
      type: String as PropType<string>,
      required: false,
    },
    addText: {
      type: String as PropType<string>,
    },
    breadcrumb: {
      type: Boolean,
      default: false,
    },
    addNewButton: {
      type: Boolean,
      default: false,
    },
    downloadButton: {
      type: Boolean,
      default: false,
    },
    downloadOutageLogs: {
      type: Boolean,
      default: false,
    },
    downloadWer: {
      type: Boolean,
      default: false,
    },
    uploadDmzOutages: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadOutlined,
  },
  setup() {
    const handleChange = (info: FileInfo) => {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    };

    const fileList = ref([]);
    return {
      fileList,
      upload_url: process.env.VUE_APP_BASEURL+'uploads',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("PPORT_TOKEN")}`,
      },
      handleChange,
    };
  },
});
