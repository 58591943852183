import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'
import router from "@/router";

const baseURL = "/sms_reports";

export default ({
    namespaced: true,
    state : {
        sms_reports: [],
        sms_reports_archived: [],
        item:{},
        status: 'loading'
    },
    actions : {
        async index({commit}: {commit: Commit}, payload={}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}`, {params: {...payload}});
            commit('SET_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },

        async indexArchive({commit}: {commit: Commit}, payload={}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}?is_archieved=true`, {params: {...payload}});
            commit('SET_ITEMS_ARCHIVED', data)
            commit('SET_STATUS', 'completed')
            return data
        },
        
        async store({dispatch}: {dispatch: Dispatch}, payload={}){
            const { data } = await axios.post(`${baseURL}`, payload);
            router.push('/business_unit')
            return data
        },

        async update({dispatch}: {dispatch: Dispatch}, payload={id: ''}){
            const { data } = await axios.put(`${baseURL}/${payload.id}`, payload);
            return data
        },

        async show({commit}: {commit: Commit}, id=""){
            const { data } = await axios.get(`${baseURL}/${id}`);
            return data
        },

        async destroy({dispatch}: {dispatch: Dispatch}, id=""){
            const { data } = await axios.delete(`${baseURL}/${id}`);
            dispatch('index')
            return data
        },

        async archive({dispatch}: {dispatch: Dispatch}, id=""){
            const { data } = await axios.delete(`${baseURL}/${id}`);
            router.push('/outage/sms-reports')
            return data
        },

        async restore({dispatch}: {dispatch: Dispatch}, id=""){
            const { data } = await axios.post(`${baseURL}/${id}/restore`);
            router.push('/outage/sms-reports')
            return data
        },

        async recipients({commit}: {commit: Commit}, payload={id:""}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}/${payload.id}/recipients`, {params: {...payload}});
            commit('SET_ITEMS', data)
            commit('SET_STATUS', 'completed')

        },

    },
    mutations:{
        SET_ITEMS(state:any, data: any){
            state.sms_reports = data.data;
        },
        SET_ITEMS_ARCHIVED(state:any, data: any){
            state.sms_reports_archived = data.data;
        },
        SET_STATUS(state: any, payload: string){
            state.status = payload;
        },
    },
    getters:{
        items(state: any){
            return state.sms_reports;
        },
        items_archived(state: any){
            return state.sms_reports_archived;
        },
        status(state: any){
            return state.status;
        }
    }
});




