<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
      <div class="logo">
        <img src="images/full-logo.png" v-if="!collapsed" />
        <img src="images/favicon.ico" v-else width="35" height="35" />
      </div>
      <a-menu mode="inline" v-model:selectedKeys="selectedKeys">
        <a-menu-item key="1">
          <home-outlined />
          <span> Dashboard</span>
        </a-menu-item>
        <a-sub-menu key="sub1">
          <template #title>
            <span>
              <appstore-outlined />
              <span> Issues</span>
            </span>
          </template>
          <a-menu-item key="2" > Incident - Technical</a-menu-item>
          <a-menu-item key="3" > Incident - Non-Technical</a-menu-item>
        </a-sub-menu>
        <a-menu-item key="4">
          <setting-outlined />
          <span> System Settings</span>
        </a-menu-item>
        <a-menu-item key="5">
          <tool-outlined />
          <span> Admin Settings</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>

    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <menu-outlined
          v-if="collapsed"
          class="trigger"
          @click="() => (collapsed = !collapsed)"
        />
        <menu-outlined
          v-else
          class="trigger"
          @click="() => (collapsed = !collapsed)"
        />
        <h1 class="app-title">
          Issues Resolution System
        </h1>

        <div>
          <a class="logout" @click="logout">
            Logout
          </a>
        </div>
        <a-space>
          <a class="logged-user"
            ><UserOutlined /> Admin
          </a>
        </a-space>
      </a-layout-header>

      <a-layout-content
        :style="{
          margin: '24px 16px',
          padding: '24px',
          background: '#fff',
          minHeight: '400px',
        }"
      >
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  components: {},

  setup() {
    const router = useRouter();

    const load_page = (route_name) => {
        router.push({ name: route_name })
    };

    return {
      selectedKeys: ref(["1"]),
      collapsed: ref(false),
      load_page
    };
  },
});
</script>
<style scoped>
/* Hamburger Menu Icon */
.trigger {
  font-size: 20px;
  line-height: 50px;
  padding: 0 10px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

/* Logo full width */
.logo {
  height: 50px;
  background: white;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Header */
.ant-layout-header {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-title{
    margin: 0;
    padding: 0;
    font-size: 1.4em;
    flex-grow: 1;
    text-align: left;
    color: #2E3192;
}

/* SideBar */
.ant-layout-sider {
  background: #00afef;
}

/* Sidebar Menu Items */
.ant-menu{
  color: white;
  background: #00afef;
  width: 201px;
}
.ant-menu-item-active{
  color: red;
}
</style>
