import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'
import router from "@/router";

const baseURL = "/users";

export default ({
    namespaced: true,
    state : {
        users: [],
        users_archived: [],
        item:{},
        status: 'loading',
        commit_hash:''
    },
    actions : {
        async index({commit}: {commit: Commit}, payload={}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}`, {params: {...payload}});
            commit('SET_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },

        async supervisorIndex({commit}: {commit: Commit}, payload={}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}/supervisor-list`, {params: {...payload}});
            commit('SET_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },

        async indexArchive({commit}: {commit: Commit}, payload={}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}?is_archieved=true`, {params: {...payload}});
            commit('SET_ITEMS_ARCHIVED', data)
            commit('SET_STATUS', 'completed')
            return data
        },
        
        async store({dispatch}: {dispatch: Dispatch}, payload={}){
            const { data } = await axios.post(`${baseURL}`, payload);
            router.push('/users')
            return data
        },

        async update({dispatch}: {dispatch: Dispatch}, payload={id: ''}){
            const { data } = await axios.put(`${baseURL}/${payload.id}`, payload);
            return data
        },

        async show({commit}: {commit: Commit}, id=""){
            const { data } = await axios.get(`${baseURL}/${id}`);
            return data
        },

        async destroy({dispatch}: {dispatch: Dispatch}, id=""){
            const { data } = await axios.delete(`${baseURL}/${id}`);
            dispatch('index')
            return data
        },

        async archive({dispatch}: {dispatch: Dispatch}, id=""){
            const { data } = await axios.delete(`${baseURL}/${id}`);
            router.push('/users')
            return data
        },

        async restore({dispatch}: {dispatch: Dispatch}, id=""){
            const { data } = await axios.post(`${baseURL}/${id}/restore`);
            router.push('/users')
            return data
        },

        async getCommitHash ({commit}: { commit: Commit }, payload: any) {
            const {data} = await axios.get(`commit_hash`, payload)
            console.log(payload)
            commit('SET_COMMIT_HASH', data)
          },

    },
    mutations:{
        SET_ITEMS(state:any, data: any){
            state.users = data.data;
        },
        SET_ITEMS_ARCHIVED(state:any, data: any){
            state.users_archived = data.data;
        },
        SET_STATUS(state: any, payload: string){
            state.status = payload;
        },
        SET_COMMIT_HASH (state: any, data: any) {
            state.commit_hash = data
          },
    },
    getters:{
        items(state: any){
            return state.users;
        },
        items_archived(state: any){
            return state.users_archived;
        },
        status(state: any){
            return state.status;
        },
        commit_hash (state: any) {
            return state.commit_hash
          },
    }
});




