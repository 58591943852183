<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: "Open Sans", "Calibri", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-weight: 400;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

h3 {
  text-align: left;
}

span,
li,
p {
  text-align: left;
}

.ant-empty-description {
  text-align: center;
}

.ant-form-item {
  margin-bottom: 10px;
}
.section-title {
  background: #2e3192;
  color: white;
  padding: 2px;
}
.form-box {
  border: 1px solid lightgray;
  padding: 0;
}

form {
  padding: 5px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
}

.ant-table-row-cell-break-word {
  /* padding: 0 !important; */
}
</style>
