import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import BaseLayout from '../components/BaseLayout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/ForgotPassword.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/ResetPassword.vue')
  },
  {
    path: '/redirect/google',
    name: 'RedirectGoogle',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Redirect.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Logout.vue')
  },
  {
    path: '/new',
    name: 'new',
    component: BaseLayout
  },
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    component: () => import('../components/BaseContainer.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/dashboard/Dashboard.vue')
      },
      //Buniness Unit
      {
        path: '/business_unit',
        name: 'business-unit',
        component: () => import('../views/admin/plants/business_unit/BusinessUnit.vue')
      },
      {
        path: '/create/business_unit',
        name: 'create-business-unit',
        component: () => import('../views/admin/plants/business_unit/Form.vue')
      },
      {
        path: '/edit/business_unit/:id',
        name: 'edit-business-unit',
        component: () => import('../views/admin/plants/business_unit/Form.vue'),
        props: true
      },
      //DMZ 
      {
        path: '/dmz_recipients',
        name: 'dmz-recipients',
        component: () => import('../views/admin/dmz/recipient/Index.vue')
      },
      {
        path: '/edit/dmz_recipients/:id',
        name: 'edit-dmz-recipients',
        component: () => import('../views/admin/dmz/recipient/Form.vue'),
        props: true
      },
      {
        path: '/create/dmz_recipients',
        name: 'create-dmz-recipients',
        component: () => import('../views/admin/dmz/recipient/Form.vue')
      },
      //Plant Technology
      {
        path: '/plant-technologies',
        name: '/plant-technologies',
        component: () => import('../views/admin/plants/plant_technology/PlantTechnology.vue')
      },
      {
        path: '/create/plant-technologies',
        name: 'create-plant-technologies',
        component: () => import('../views/admin/plants/plant_technology/Form.vue')
      },
      {
        path: '/edit/plant-technologies/:id',
        name: 'edit-plant-technologies',
        component: () => import('../views/admin/plants/plant_technology/Form.vue'),
        props: true
      },

      //Plant
      {
        path: '/plants',
        name: '/plants',
        component: () => import('../views/admin/plants/plant/Plant.vue')
      },
      {
        path: '/create/plants',
        name: 'create-plants',
        component: () => import('../views/admin/plants/plant/Form.vue')
      },
      {
        path: '/edit/plants/:id',
        name: 'edit-plants',
        component: () => import('../views/admin/plants/plant/Form.vue'),
        props: true
      },

      //Unit
      {
        path: '/units',
        name: '/units',
        component: () => import('../views/admin/plants/unit/Unit.vue')
      },
      {
        path: '/create/units',
        name: 'create-units',
        component: () => import('../views/admin/plants/unit/Form.vue')
      },
      {
        path: '/edit/units/:id',
        name: 'edit-units',
        component: () => import('../views/admin/plants/unit/Form.vue'),
        props: true
      },

      //Engine
      {
        path: '/engines',
        name: '/engines',
        component: () => import('../views/admin/plants/engine/Engine.vue')
      },
      {
        path: '/create/engines',
        name: 'create-engines',
        component: () => import('../views/admin/plants/engine/Form.vue')
      },
      {
        path: '/edit/engines/:id',
        name: 'edit-engines',
        component: () => import('../views/admin/plants/engine/Form.vue'),
        props: true
      },

      //Cause Code Type
      {
        path: '/cause-code-types',
        name: 'cause-code-types',
        component: () => import('../views/admin/cause_code/types/Types.vue')
      },
      {
        path: '/create/cause-code-types',
        name: 'create-cause-code-types',
        component: () => import('../views/admin/cause_code/types/Form.vue')
      },
      {
        path: '/edit/cause-code-types/:id',
        name: 'edit-cause-code-types',
        component: () => import('../views/admin/cause_code/types/Form.vue'),
        props: true
      },

      //Cause Code Category
      {
        path: '/cause-code-categories',
        name: 'cause-code-categories',
        component: () => import('../views/admin/cause_code/category/Category.vue')
      },
      {
        path: '/create/cause-code-categories',
        name: 'create-cause-code-categories',
        component: () => import('../views/admin/cause_code/category/Form.vue')
      },
      {
        path: '/edit/cause-code-categories/:id',
        name: 'edit-cause-code-categories',
        component: () => import('../views/admin/cause_code/category/Form.vue'),
        props: true
      },

      //Cause Code SubCategory
      {
        path: '/cause-code-sub-categories',
        name: 'cause-code-sub-categories',
        component: () => import('../views/admin/cause_code/sub_category/SubCategory.vue')
      },
      {
        path: '/create/cause-code-sub-categories',
        name: 'create-cause-code-sub-categories',
        component: () => import('../views/admin/cause_code/sub_category/Form.vue')
      },
      {
        path: '/edit/cause-code-sub-categories/:id',
        name: 'edit-cause-code-sub-categories',
        component: () => import('../views/admin/cause_code/sub_category/Form.vue'),
        props: true
      },

      //Cause Code Description
      {
        path: '/cause-code-descriptions',
        name: 'cause-code-descriptions',
        component: () => import('../views/admin/cause_code/description/Description.vue')
      },
      {
        path: '/create/cause-code-descriptions',
        name: 'create-cause-code-descriptions',
        component: () => import('../views/admin/cause_code/description/Form.vue')
      },
      {
        path: '/edit/cause-code-descriptions/:id',
        name: 'edit-cause-code-descriptions',
        component: () => import('../views/admin/cause_code/description/Form.vue'),
        props: true
      },

      //Cause Code
      {
        path: '/cause-codes',
        name: 'cause-codes',
        component: () => import('../views/admin/cause_code/code/Code.vue')
      },
      {
        path: '/create/cause-codes',
        name: 'create-cause-codes',
        component: () => import('../views/admin/cause_code/code/Form.vue')
      },
      {
        path: '/edit/cause-codes/:id',
        name: 'edit-cause-codes',
        component: () => import('../views/admin/cause_code/code/Form.vue'),
        props: true
      },



      //Group
      {
        path: '/groups',
        name: 'groups',
        component: () => import('../views/admin/recipient_management/group/Group.vue')
      },
      {
        path: '/create/groups',
        name: 'create-groups',
        component: () => import('../views/admin/recipient_management/group/Form.vue')
      },
      {
        path: '/edit/groups/:id',
        name: 'edit-groups',
        component: () => import('../views/admin/recipient_management/group/Form.vue'),
        props: true
      },

      //Sms Recipient
      {
        path: '/sms-recipients',
        name: 'sms-recipients',
        component: () => import('../views/admin/recipient_management/sms_recipient/Sms.vue')
      },
      {
        path: '/create/sms-recipients',
        name: 'create-sms-recipients',
        component: () => import('../views/admin/recipient_management/sms_recipient/Form.vue')
      },
      {
        path: '/edit/sms-recipients/:id',
        name: 'edit-sms-recipients',
        component: () => import('../views/admin/recipient_management/sms_recipient/Form.vue'),
        props: true
      },

      //Enmail Recipient
      {
        path: '/email-recipients',
        name: 'email-recipients',
        component: () => import('../views/admin/recipient_management/email_recipient/Email.vue')
      },
      {
        path: '/create/email-recipients',
        name: 'create-email-recipients',
        component: () => import('../views/admin/recipient_management/email_recipient/Form.vue')
      },
      {
        path: '/edit/email-recipients/:id',
        name: 'edit-email-recipients',
        component: () => import('../views/admin/recipient_management/email_recipient/Form.vue'),
        props: true
      },

      //Location
      {
        path: '/locations',
        name: 'locations',
        component: () => import('../views/admin/location/Location.vue')
      },
      {
        path: '/create/locations',
        name: 'create-locations',
        component: () => import('../views/admin/location/Form.vue')
      },
      {
        path: '/edit/locations/:id',
        name: 'edit-locations',
        component: () => import('../views/admin/location/Form.vue'),
        props: true
      },

      //User
      {
        path: '/users',
        name: 'users',
        component: () => import('../views/admin/user/user/User.vue')
      },
      {
        path: '/create/users',
        name: 'create-users',
        component: () => import('../views/admin/user/user/Form.vue')
      },
      {
        path: '/edit/users/:id',
        name: 'edit-users',
        component: () => import('../views/admin/user/user/Form.vue'),
        props: true
      },

      //Delegation
      {
        path: '/delegations',
        name: 'delegations',
        component: () => import('../views/admin/user/delegation/Delegation.vue')
      },
      {
        path: '/create/delegations',
        name: 'create-delegations',
        component: () => import('../views/admin/user/delegation/Form.vue')
      },
      {
        path: '/edit/delegations/:id',
        name: 'edit-delegations',
        component: () => import('../views/admin/user/delegation/Form.vue'),
        props: true
      },

      //Role
      {
        path: '/roles',
        name: 'roles',
        component: () => import('../views/admin/user/role/Role.vue')
      },
      {
        path: '/create/roles',
        name: 'create-roles',
        component: () => import('../views/admin/user/role/Form.vue')
      },
      {
        path: '/edit/roles/:id',
        name: 'edit-roles',
        component: () => import('../views/admin/user/role/Form.vue'),
        props: true
      },


      //Report Type
      {
        path: '/outage-types',
        name: 'outage-types',
        component: () => import('../views/admin/outage/report_type/ReportType.vue')
      },
      {
        path: '/create/outage-types',
        name: 'create-outage-types',
        component: () => import('../views/admin/outage/report_type/Form.vue')
      },
      {
        path: '/edit/outage-types/:id',
        name: 'edit-outage-types',
        component: () => import('../views/admin/outage/report_type/Form.vue'),
        props: true
      },

      //Templates
      {
        path: '/outage-templates',
        name: 'outage-templates',
        component: () => import('../views/admin/outage/template/Template.vue')
      },
      {
        path: '/create/outage-templates',
        name: 'create-outage-templates',
        component: () => import('../views/admin/outage/template/SelectPlant.vue')
      },
      {
        path: '/edit/outage-template/:id',
        name: 'edit-outage-template',
        component: () => import('../views/admin/outage/template/SelectPlant.vue'),
        props: true
      },

      //Classification
      {
        path: '/outage-classifications',
        name: 'outage-classifications',
        component: () => import('../views/admin/outage/classification/Classification.vue')
      },
      {
        path: '/create/outage-classifications',
        name: 'create-outage-classifications',
        component: () => import('../views/admin/outage/classification/Form.vue')
      },
      {
        path: '/edit/outage-classifications/:id',
        name: 'edit-outage-classifications',
        component: () => import('../views/admin/outage/classification/Form.vue'),
        props: true
      },


      //Outage Log
      {
        path: '/outage-logs',
        name: 'outage-logs',
        component: () => import('../views/admin/report_log/outage/Outage.vue')
      },
      {
        path: '/create/outage-logs',
        name: 'create-outage-logs',
        component: () => import('../views/admin/report_log/outage/SelectPlant.vue')
      },
      {
        path: '/edit/outage-logs/:id',
        name: 'edit-outage-logs',
        component: () => import('../views/admin/report_log/outage/SelectPlant.vue'),
        props: true
      },
      {
        path: '/edit/outage-logs-planned/:id',
        name: 'edit-outage-logs-planned',
        component: () => import('../views/admin/report_log/outage/SelectPlant.vue'),
        props: true
      },

      
      //Sms Report
      {
        path: '/outage/sms-reports',
        name: 'sms-reports',
        component: () => import('../views/admin/report_log/sms_report/SmsReport.vue')
      },
      {
        path: '/outage/sms-reports/:delivery_id/outage/:id',
        name: 'edit-sms-reports',
        component: () => import('../views/admin/report_log/outage/SelectPlant.vue'),
        props: true
      },

      //Email Report
      {
        path: '/email-reports',
        name: 'email-reports',
        component: () => import('../views/admin/report_log/email_report/EmailReport.vue')
      },
      {
        path: '/email-reports/:delivery_id/outage/:id',
        name: 'edit-email-reports',
        component: () => import('../views/admin/report_log/outage/SelectPlant.vue'),
        props: true
      },
      {
        path: '/edit/email-reports/:id',
        name: 'edit-email-reports',
        component: () => import('../views/admin/report_log/outage/SelectPlant.vue'),
        props: true
      },

      //planned-outage-logs
      {
        path: '/planned-outage-logs',
        name: 'planned-outage-logs',
        component: () => import('../views/admin/report_log/gomp/Index.vue')
      },
      {
        path: '/create/planned-outage-logs',
        name: 'create-planned-outage-logs',
        component: () => import('../views/admin/report_log/outage/SelectPlant.vue')
      },
      {
        path: '/edit/planned-outage-logs/:id',
        name: 'edit-planned-outage-logs',
        component: () => import('../views/admin/report_log/outage/SelectPlant.vue'),
        props: true
      },
      //wer reports
      {
        path: '/wer-reports',
        name: 'wer-reports',
        component: () => import('../views/admin/report_log/wer/Index.vue')
      },

      //Alarms DMZ
      {
        path: '/alarms',
        name: 'alarms',
        component: () => import('../views/admin/report_log/alarm/Alarm.vue')
      },
      {
        path: '/create/alarms',
        name: 'create-alarm',
        component: () => import('../views/admin/report_log/alarm/Form.vue')
      },
      {
        path: '/edit/alarms/:id',
        name: 'edit-alarm',
        component: () => import('../views/admin/report_log/alarm/Form.vue'),
        props: true
      },

      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile.vue'),
        props: true
      },
      {
        path: '/audit-logs',
        name: 'audit-logs',
        component: () => import('../views/admin/audit_log/AuditLog.vue'),
        props: true
      },

      // Auth Clients
      {
        path: '/auth-clients',
        name: 'auth-clients',
        component: () => import('../views/admin/auth_client/AuthClient.vue'),
        props: true
      },
      {
        path: '/create/auth-client',
        name: 'create-auth-clients',
        component: () => import('../views/admin/auth_client/Form.vue')
      },
      {
        path: '/edit/auth-client/:id',
        name: 'edit-auth-clients',
        component: () => import('../views/admin/auth_client/Form.vue'),
        props: true
      },
    ]
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name === 'RedirectGoogle' && !window.localStorage.getItem("PPORT_TOKEN")) {
    next()
    return
  }

  if (to.name !== 'Login' && to.name !== 'Logout' && to.name !== 'ForgotPassword' && to.name !== 'ResetPassword'
    && !window.localStorage.getItem("PPORT_TOKEN"))
    next({ name: 'Login' })
  else next()
})

export default router
