import axios from "axios";
import router from './router'
import { h } from 'vue';
import { Modal } from 'ant-design-vue';

const api = process.env.VUE_APP_BASEURL;
const axiosRequest = axios.create({
    baseURL: api,
    timeout: 20000,
    headers: {
        Authorization: `Bearer ${window.localStorage.getItem("PPORT_TOKEN")}`,
        // account: window.localStorage.getItem("selectedAccount"),
        // accountIndex: window.localStorage.getItem("accountIndex")
    }
});

// RESPONSE
// Add a response interceptor
axiosRequest.interceptors.response.use(
    function (response) {
        // Do something with response data
        const url = response.config.url ? response.config.url : "";
        if (response.config.method == "put") {
            Modal.success({
                title: 'Success',
                content: h('div', {}, [
                    h('p', response.data.message)
                ]),
            });
        } else if (response.config.method == "post" && !url.includes('getWer') && !url.includes('send-sms-raw') && !url.includes('send-sms-group') && !url.includes('send-email-oer') && !url.includes('send-email-ser') && !url.includes('send-oer-email-group') && !url.includes('send-ser-email-group') && !url.includes('send-closed-sms-group') && !url.includes('send-closed-sms-raw') && !url.includes('manualSend')) {
            Modal.success({
                title: 'Success',
                content: h('div', {}, [
                    h('p', response.data.message)
                ]),
            });
        } else if (response.config.method == "delete") {
            Modal.success({
                title: 'Success',
                content: h('div', {}, [
                    h('p', response.data.message)
                ]),
            });
        }
        return response;
    },
    function (error) {
        // Do something with response error

        // if (error.response.status == 401) {
        //     // store.commit("SET_ERROR", 401);
        //     router.push('/login')
        //     swal.fire({
        //         title: "Permission Denied",
        //         text: "You don't have permission to perform such operation",
        //         icon: "error",
        //         confirmButtonText: "OK",
        //     });

        // }
        if (error.response.status == 422) {
            const item = error.response.data.errors;
            let errors = "";
            for (const key in item) {
                errors += `${item[key]}`;
                break
            }

            Modal.error({
                title: 'Error',
                content: errors,
            })
        }

        if (error.response.status == 500) {
            Modal.error({
                title: 'Error',
                content: error.response.data.message,
            })
        }

        if (error.response.status == undefined) {
            router.push('/login')
        }


        return Promise.reject(error);
    }
);

export default axiosRequest;
// function else if() {
//     throw new Error("Function not implemented.");
// }

