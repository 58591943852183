import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'
import router from "@/router";

const baseURL = "/outage_templates";

export default ({
    namespaced: true,
    state: {
        outage_templates: [],
        outage_templates_archived: [],
        item_dmz: [],
        item: {},
        status: 'loading'
    },
    actions: {
        async index({ commit }: { commit: Commit }, payload = {}) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}`, { params: { ...payload } });
            commit('SET_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },

        async indexArchive({ commit }: { commit: Commit }, payload = {}) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}?is_archieved=true`, { params: { ...payload } });
            commit('SET_ITEMS_ARCHIVED', data)
            commit('SET_STATUS', 'completed')
            return data
        },

        async indexDmz({ commit }: { commit: Commit }, payload = {}) {
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}?is_dmz=1`, { params: { ...payload } });
            commit('SET_ITEMS_DMZ', data)
            commit('SET_STATUS', 'completed')
            return data
        },
        async store({ dispatch }: { dispatch: Dispatch }, payload = {}) {
            const { data } = await axios.post(`${baseURL}`, payload);
            router.push('/outage-templates')
            return data
        },


        async checkDmzPlants({ commit }: { commit: Commit }, payload = {}) {
            const { data } = await axios.get(`${baseURL}/checkDmzPlant`, { params: { ...payload } });
            return data
        },

        async update({ dispatch }: { dispatch: Dispatch }, payload = { id: '' }) {
            const { data } = await axios.put(`${baseURL}/${payload.id}`, payload);
            router.push('/outage-templates')
            return data
        },

        async show({ commit }: { commit: Commit }, id = "") {
            const { data } = await axios.get(`${baseURL}/${id}`);
            return data
        },

        async destroy({ dispatch }: { dispatch: Dispatch }, id = "") {
            const { data } = await axios.delete(`${baseURL}/${id}`);
            dispatch('index')
            return data
        },

        async archive({ dispatch }: { dispatch: Dispatch }, id = "") {
            const { data } = await axios.delete(`${baseURL}/${id}`);
            router.push('/outage-templates')
            return data
        },

        async restore({ dispatch }: { dispatch: Dispatch }, id = "") {
            const { data } = await axios.post(`${baseURL}/${id}/restore`);
            router.push('/outage-templates')
            return data
        },

        async engines({ commit }: { commit: Commit }, id = "") {
            const { data } = await axios.get(`${baseURL}/${id}/engines`);
            return data
        },

    },
    mutations: {
        SET_ITEMS(state: any, data: any) {
            state.outage_templates = data.data;
        },
        SET_ITEMS_ARCHIVED(state: any, data: any) {
            state.outage_templates_archived = data.data;
        },
        SET_ITEMS_DMZ(state: any, data: any) {
            state.item_dmz = data.data;
        },
        SET_STATUS(state: any, payload: string) {
            state.status = payload;
        },
    },
    getters: {
        items(state: any) {
            return state.outage_templates;
        },
        items_archived(state: any) {
            return state.outage_templates_archived;
        },
        items_dmz(state: any) {
            return state.item_dmz;
        },
        status(state: any) {
            return state.status;
        }
    }
});




