import { User } from '@/classes/user';
import { Commit } from 'vuex';
import axios from '../../axios'

export default ({
    namespaced: true,
    state : {
        user: new User
    },
    actions: {
        setUser: ({commit}:{commit: Commit} , user: User) => commit('SET_AUTH_USER', user),

        async logout () {
            const {data} = await axios.get(`/logout`)
            return data
        },

    },
    mutations: {
        SET_AUTH_USER(state: {user: User}, user: User ){
            state.user = user
        },
    },
    getters: {
        user(state: {user: User}){
            return state.user;
        },
        // department_head_id(state: {user: User}):string{
        //     return state.user.head;
        // },
        // permissions(state: {user: User}){
        //     return state.user.permissions
        // }
    }
})
