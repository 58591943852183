import { Entity } from '@/interfaces/entity';
import { Role } from './role';
import { Manager } from './manager';

export class User implements Entity {
  id: string;
  email: string;
  last_name: string;
  first_name: string;
  position: string;
  esignature: string;
  role: Role;
  manager: Manager;
  // permissions: string[];

  constructor(id = '', email = '', last_name = "", first_name = "", position = "", esignature = "", role = new Role(), manager = new Manager()) {
    this.id = id;
    this.email = email;
    this.last_name = last_name;
    this.first_name = first_name;
    this.position = position;
    this.esignature = esignature;
    this.role = role;
    this.manager = manager;
    //this.permissions = permissions;
  }

  view = (name: string) => {
    return this.role.permissions.find((element: any) => {
      if (element.code === name) {
        return true
      } else {
        return false
      }
    })
  }

  //Permissions to sidebar
  hasPermissionToPlant() {
    return this.view('B01') ||
      this.view('B02') ||
      this.view('B03') ||
      this.view('B04')
  }

  hasPermissionToReportLog() {
    return this.view('F07')
  }

  hasPermissionToOutageLog() {
    return this.view('F07')
  }

  hasPermissionToSMSandEmailReports() {
    return this.view('F08') ||
      this.view('F09')
  }

  hasPermissionToOutageModules() {
    return this.view('D01') ||
      this.view('D02') ||
      this.view('D03') ||
      this.view('D04') ||
      this.view('D05')
  }

  hasPermissionToPlannedOutageModules() {
    return this.view('D06') ||
      this.view('D07') ||
      this.view('D08') ||
      this.view('D09') ||
      this.view('D10')
  }

  hasPermissionToOutageTemplateModules() {
    return this.view('D11') ||
      this.view('D12') ||
      this.view('D13') ||
      this.view('D14') ||
      this.view('D15')
  }

  hasPermissionToWERReportModules() {
    return this.view('D06') ||
      this.view('D16') ||
      this.view('D17') ||
      this.view('D18') ||
      this.view('D19')
  }

  hasPermissionToCauseCodeModules() {
    return this.view('C01') ||
      this.view('C02') ||
      this.view('C03') ||
      this.view('C04')
  }

  hasPermissionToLocationModule() {
    return this.view('I01')
  }

  hasPermissionToUserModules() {
    return this.view('A01') ||
      this.view('A02') ||
      this.view('A03') ||
      this.view('A04') ||
      this.view('A05') ||
      this.view('A06') ||
      this.view('A07') ||
      this.view('A08') ||
      this.view('H01') ||
      this.view('H02') ||
      this.view('H03') ||
      this.view('H04') ||
      this.view('H05')
  }

  hasPermissionToUserSubModule() {
    return this.view('A01') ||
      this.view('A02') ||
      this.view('A03') ||
      this.view('A04')
  }

  hasPermissionToRoleSubModule() {
    return this.view('A05') ||
      this.view('A06') ||
      this.view('A07') ||
      this.view('A08')
  }

  hasPermissionToDeligationSubModule() {
    return this.view('H01') ||
      this.view('H02') ||
      this.view('H03') ||
      this.view('H04') ||
      this.view('H05')
  }

  hasPermissionToRecipientModules() {
    return this.view('G01') ||
      this.view('G02') ||
      this.view('G03') ||
      this.view('G04') ||
      this.view('G05') ||
      this.view('G06') ||
      this.view('G07') ||
      this.view('G08') ||
      this.view('G09') ||
      this.view('G10') ||
      this.view('G11') ||
      this.view('G12') ||
      this.view('G13') ||
      this.view('G14') ||
      this.view('G15')
  }

  hasPermissionToGroupModule() {
    return this.view('G11') ||
      this.view('G12') ||
      this.view('G13') ||
      this.view('G14') ||
      this.view('G15')
  }

  hasPermissionToSmsRecipientModule() {
    return this.view('G06') ||
      this.view('G07') ||
      this.view('G08') ||
      this.view('G09') ||
      this.view('G10') ||
      this.view('G11')
  }

  hasPermissionToEmailRecipientModule() {
    return this.view('G01') ||
      this.view('G02') ||
      this.view('G03') ||
      this.view('G04') ||
      this.view('G05')
  }

  hasPermissionToAuditLogModule() {
    return this.view('J01') ||
      this.view('J02')
  }

  get name() {
    return this.first_name + ' ' + this.last_name
  }

  get auth_id() {
    return this.id;
  }


  isAdmin() {
    return this.role.name == 'Admin';
  }
}