import axios from '../../axios'
import { Commit, Dispatch } from 'vuex'

const baseURL = '/audit_logs'

export default ({
  namespaced: true,
  state: {
    audit_logs: [],
    modules: [],
    item: {},
    status: 'loading'
  },
  actions: {
    async index ({commit}: { commit: Commit }, payload = {}) {
      commit('SET_STATUS', 'loading')
      const {data} = await axios.get(`${baseURL}`, {params: {...payload}})
      commit('SET_ITEMS', data)
      commit('SET_STATUS', 'completed')
      return data
    },
    async getModules ({commit}: { commit: Commit }, payload = {}) {
      const {data} = await axios.get(`${baseURL}` + '/modules', {params: {...payload}})
      commit('SET_MODULES', data)
      return data
    },
  },
  mutations: {
    SET_ITEMS (state: any, data: any) {
      state.audit_logs = data.data
    },
    SET_MODULES (state: any, data: any) {
      state.modules = data.data
    },
    SET_STATUS (state: any, payload: string) {
      state.status = payload
    },
  },
  getters: {
    items (state: any) {
      return state.audit_logs
    },
    modules (state: any) {
      return state.modules
    },
    status (state: any) {
      return state.status
    }
  }
})




