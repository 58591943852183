import axios from "../../axios";
import { Commit, Dispatch } from "vuex";
import router from "@/router";

const baseURL = "/wer";

export default {
  namespaced: true,
  state: {
    item: {},
    status: "loading",
  },
  actions: {
    async showWer(
      { dispatch }: { dispatch: Dispatch },
      payload = { data: [] }
    ) {
      const { data } = await axios.post(`${baseURL}/getWer`, payload.data);
      return data;
    },

    async saveWer(
      { dispatch }: { dispatch: Dispatch },
      payload = { data: [] }
    ) {
      const { data } = await axios.post(`${baseURL}/saveWer`, payload.data);
      return data;
    },

    async manualSend(
      { dispatch }: { dispatch: Dispatch },
      payload = { data: [] }
    ) {
      const { data } = await axios.post(`${baseURL}/manualSend`, payload.data);
      return data;
    },

    async uploadWer(
      { dispatch }: { dispatch: Dispatch },
      payload = { data: [] }
    ) {
      const headers = { "Content-Type": "multipart/form-data" };
      const { data } = await axios.post(
        `${process.env.VUE_APP_URL}/api/uploadWer`,
        payload.data,
        { headers }
      );
      return data;
    },

    async getDateWeek({ commit }: { commit: Commit }, date = "") {
      const { data } = await axios.get(`${baseURL}/${date}`);
      return data;
    },
  },
  mutations: {
    SET_ITEMS(state: any, data: any) {
      state.outages = data.data;
    },
    SET_STATUS(state: any, payload: string) {
      state.status = payload;
    },
  },
  getters: {
    items(state: any) {
      return state.outages;
    },
    status(state: any) {
      return state.status;
    },
  },
};
