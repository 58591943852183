import { Entity } from "@/interfaces/entity";

export class Manager implements Entity{
  id: string;
  first_name: string;
  last_name: string;
  esignature: string;
  position: string;

  constructor(id = "", first_name = "", last_name="", esignature="", position="") {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.esignature = esignature
    this.position = position
  }
}
