import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'

const baseURL = "/permission_categories";

export default ({
  namespaced: true,
  state : {
    categories: [],
    item:{},
    status: 'loading'
  },
  actions : {
    async index({commit}: {commit: Commit}){
      commit('SET_STATUS', 'loading')
      const { data } = await axios.get(`${baseURL}`);
      commit('SET_ITEMS', data)
      commit('SET_STATUS', 'completed')
      return data
    },
  },
  mutations:{
    SET_ITEMS(state:any, data: any){
      state.categories = data.data;
    },
    SET_STATUS(state: any, payload: string){
      state.status = payload;
    },
  },
  getters:{
    items(state: any){
      return state.categories;
    },
    status(state: any){
      return state.status;
    }
  }
});




